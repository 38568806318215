import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import lottie, { AnimationItem } from "lottie-web"
import { useSpring, animated } from "react-spring"

import theme, { color, space } from "../../theme"

import Text from "../text"
import Card, { Props as CardProps } from "./card"

export type SlideProps = {
  title: string
  card1: CardProps
  card2: CardProps
}
export type Props = {
  slides: SlideProps[]
  breakpoint: string
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 610px;
  margin: 0 auto;
  background-color: ${color("rose")};
  overflow: hidden;
`
const AnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 360px;
    height: 510px;
  }
`
const Wrapper = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  grid-template-columns: 1fr min-content min-content min-content 1fr;
  grid-gap: 0;
  align-items: center;
  justify-items: center;
`
const Control = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 100px 250px 100px 1fr;
  grid-gap: ${space("normal")};
  align-items: center;
  justify-items: center;
`
const SVG = styled.svg<{ reverse?: boolean }>`
  transform: scaleX(${(p: any) => (p.reverse ? -1 : 1)});
  transform-origin: center;
  transform-box: fill-box;
  cursor: pointer;
`

const Arrow: React.FC<{ color: ColorKeys; reverse?: boolean }> = ({
  color,
  reverse,
  ...props
}) => (
  <SVG
    width="45px"
    height="12px"
    viewBox="0 0 45 12"
    reverse={reverse}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <line
      strokeWidth="2"
      stroke={theme.colors[color]}
      x1="0"
      y1="6"
      x2="35"
      y2="6"
    ></line>
    <path fill={theme.colors[color]} d="M35,0 L45,6 35,12 35,0 Z"></path>
  </SVG>
)

const Title: React.FC<{ title: string }> = ({ title }) => {
  const [text, setT] = React.useState(title)

  const props = useSpring({
    config: { delay: 1000 },
    from: { opacity: 1 },
    to: async (next: any) => {
      await next({ opacity: 0 })
      setT(title)
      await next({ opacity: 1 })
    },
  })

  return (
    <animated.div style={props}>
      <Text color="white" type="h5">
        {text}
      </Text>
    </animated.div>
  )
}

const Slides: FC<Props> = ({ slides }) => {
  const [index, setIndex] = React.useState(0)
  const [animation, setAnimation] = React.useState<AnimationItem>()
  const animationRef = React.createRef()
  const [loaded, setL] = React.useState(false)

  React.useEffect(() => {
    if (animationRef.current && !loaded) {
      const a = lottie.loadAnimation({
        container: animationRef.current as any,
        renderer: "svg",
        loop: false,
        autoplay: false,
        name: "cog",
        path: "/animations/engines/cog.json",
      })
      a.setSpeed(2)
      setAnimation(a)
      setL(true)
    }
  }, [animationRef])
  const prevIdx = () => {
    if (animation) {
      animation.stop()
      if (index - 1 > 0) {
        animation.goToAndStop(132, true)
        animation.setDirection(-1)
        setIndex(index - 1)
      } else {
        animation.setDirection(1)
        setIndex(slides.length - 1)
      }
      animation.play()
    }
  }
  const nextIdx = () => {
    if (animation) {
      animation.stop()
      if (index + 1 < slides.length) {
        animation.setDirection(1)
        setIndex(index + 1)
      } else {
        animation.goToAndStop(132, true)
        animation.setDirection(-1)
        setIndex(0)
      }
      animation.play()
    }
  }

  React.useEffect(() => {
    const timer = setInterval(nextIdx, 6000)
    return () => clearInterval(timer)
  }, [animation, index])

  return (
    <Container>
      <Wrapper>
        <div />
        <div
          css={`
            position: relative;
            width: 350px;
            height: 100%;
            overflow: hidden;
          `}
        >
          <div
            css={`
              position: absolute;
              top: 0;
              width: 100%;
              height: ${slides.length * 500}px;
              transition: transform 0.6s 0.6s cubic-bezier(0.37, 0.15, 0.47, 1);
              transform: translateY(-${500 * index}px);
            `}
          >
            {slides.map((s, idx) => (
              <Card
                key={`card_${idx}_${s.key}`}
                isIf
                bgBadge="green"
                slide={s.card1}
                index={idx}
              />
            ))}
          </div>
        </div>
        <AnimationContainer ref={animationRef} />
        <div
          css={`
            position: relative;
            width: 350px;
            height: 100%;
            overflow: hidden;
          `}
        >
          <div
            css={`
              position: absolute;
              top: 0;
              width: 100%;
              height: ${slides.length * 500}px;
              transition: transform 0.6s 0.6s cubic-bezier(0.37, 0.15, 0.47, 1);
              transform: translateY(${500 * index}px);
            `}
          >
            {slides.map((s, idx) => (
              <Card
                key={`card_${idx}_${s.key}`}
                isIf={false}
                reverse
                bgBadge="green"
                slide={s.card2}
                index={idx}
              />
            ))}
          </div>
        </div>
        <div />
      </Wrapper>
      <Control>
        <div />
        <Arrow color="white" reverse onClick={prevIdx} />
        <Title title={slides[index].title} />
        <Arrow color="white" onClick={nextIdx} />
        <div />
      </Control>
    </Container>
  )
}

export default Slides
