import * as React from "react"

import useBreakpoints from "../../utils/use-breakpoint"

import Desktop from "./desktop"
import Mobile from "./mobile"

const Slides: React.FC<any> = props => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 767, desktop: 1144 },
  })
  switch (breakpoint) {
    case "unknown":
      return null
    case "desktop":
      return <Desktop {...props} breakpoint={breakpoint} />
    default:
      return <Mobile {...props} breakpoint={breakpoint} />
  }
}
export default Slides
