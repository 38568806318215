import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { Helmet } from "react-helmet"

import { space } from "../theme"

import Title from "../components/title"
import Counter from "../components/counter"
import Text from "../components/text"
import Layout from "../components/layout"
import TextWithImage from "../blocks/TextWithImage"
import BigImage from "../blocks/BigImage"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  justify-content: center;
  padding: 0 2rem;
  margin: ${space("normal")} auto;
  margin-top: ${space("large")};
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const ContainerSlide = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  grid-gap: 0.5rem;
`
const SlideText = styled(Text).attrs({
  type: "h5",
  align: "center",
  color: "white",
})``

const HowWeDoIt = () => (
  <Layout>
    <Helmet>
      <title>Paybase - How we do it</title>
      <meta property="og:title" content="Paybase - How we do it" />
    </Helmet>
    <Title copy="How we do it" />
    <Wrapper>
      <Container>
        <Text color="trueblack">
          The Paybase solution lets us configure infinite customisable
          capabilities for your business via our API, enabling you to hold the
          reins on innovation. We are completely white label, giving you
          end-to-end control of your branding, of communication with your users
          and of dispute management. Your product, your rules.
        </Text>
      </Container>
    </Wrapper>
    <TextWithImage
      data={{
        copy: "Onboarding Engine:",
        title: "A balanced approach to Due Diligence",
        content:
          "Our tiered Due Diligence process ensures strong security without negatively impacting UX. Through real-time transaction monitoring, additional information can be requested automatically as a user’s level of risk increases, giving you peace of mind that your platform and its users are safe.",
        // image: {
        //   src: "/how-we-do-it/img1.svg",
        //   alt: "placeholder alt",
        // },
        animation: "/animations/engines/onboarding-engine.json",
        links: [{ copy: "Get in touch", to: "https://trustshare.co" }],
      }}
      options={{
        copyColor: "blue",
        titleColor: "black",
        padding: "2rem",
        anchor: { id: "duediligence", top: "-80px" },
      }}
    />
    <BigImage
      data={{
        title: "Rules Engine:",
        subtitle: "Choose the rules, disrupt the market",
        content:
          "Create unique Rules for your business and change the way payments are routed depending on different situations. You may wish to remove fees if a seller has not made a sale for X months, for instance, or offer cashback to a user on their Yth purchase. This level of flexibility enables a plethora of payments and product possibilities.",
        slides: [
          {
            title: "Routing & Split",
            card1: {
              key: "card16",
              bg: "darkgreen",
              content: <SlideText>Influencer-driven sale</SlideText>,
            },
            card2: {
              key: "card26",
              bg: "lavender",
              content: (
                <ContainerSlide>
                  <Text type="h5" color="black">
                    Influencer receives
                  </Text>
                  <Text type="headline" color="black">
                    10%
                  </Text>
                </ContainerSlide>
              ),
            },
          },
          {
            title: "Escrow Outcomes",
            card1: {
              key: "card12",
              bg: "lightblue",
              content: (
                <Counter
                  start={3}
                  end={7}
                  step={2}
                  duration={3000}
                  direction="alternate"
                  loop
                  prefix="Delivery is "
                  suffix=" days late"
                  textStyle={{ type: "h5", color: "black", align: "center" }}
                />
              ),
            },
            card2: {
              key: "card22",
              bg: "red",
              content: (
                <Counter
                  start={25}
                  end={35}
                  step={5}
                  duration={3000}
                  direction="alternate"
                  loop
                  prefix="Automated buyer refund "
                  suffix="%"
                  textStyle={{ type: "h5", color: "white", align: "center" }}
                />
              ),
            },
          },
          {
            title: "Loyalty & Rewards",
            card1: {
              key: "card11",
              bg: "blue",
              content: (
                <ContainerSlide>
                  <SlideText>Week of Merchant's birthday</SlideText>
                </ContainerSlide>
              ),
            },
            card2: {
              key: "card21",
              bg: "purple",
              content: (
                <ContainerSlide>
                  <SlideText>No merchant fees</SlideText>
                </ContainerSlide>
              ),
            },
          },
          {
            title: "Instant Payout",
            card1: {
              key: "card13",
              bg: "purple",
              content: (
                <ContainerSlide>
                  <SlideText>Transaction over</SlideText>
                  <Text type="headline" color="white">
                    £1000
                  </Text>
                </ContainerSlide>
              ),
            },
            card2: {
              key: "card23",
              bg: "blue",
              content: (
                <ContainerSlide>
                  <SlideText>Automated withdrawal</SlideText>
                </ContainerSlide>
              ),
            },
          },
          {
            title: "Tailored Merchant Fees",
            card1: {
              key: "card14",
              bg: "red",
              content: (
                <ContainerSlide>
                  <SlideText>Transaction volume below</SlideText>
                  <Counter
                    start={500}
                    end={1500}
                    step={1000}
                    duration={3000}
                    loop
                    prefix="£"
                    textStyle={{
                      type: "headline",
                      color: "white",
                      align: "center",
                    }}
                  />
                </ContainerSlide>
              ),
            },
            card2: {
              key: "card24",
              bg: "lightblue",
              content: (
                <ContainerSlide>
                  <Counter
                    start={8}
                    end={15}
                    step={7}
                    duration={3000}
                    direction="reverse"
                    loop
                    suffix="%"
                    textStyle={{
                      type: "headline",
                      color: "black",
                      align: "center",
                    }}
                  />
                  <Text type="h5" color="black">
                    merchant fees
                  </Text>
                </ContainerSlide>
              ),
            },
          },
          {
            title: "Custom/User-defined",
            card1: {
              key: "card17",
              bg: "lavender",
              content: (
                <Text type="h5" color="black" align="center">
                  Need something specific?
                </Text>
              ),
            },
            card2: {
              key: "card27",
              bg: "darkgreen",
              content: (
                <Text type="h5" color="black" align="center">
                  Create your own rule
                </Text>
              ),
            },
          },
        ],
        link: { copy: "Get in touch", to: "https://trustshare.co" },
      }}
      options={{
        anchor: { id: "rulesengine", top: "-80px" },
      }}
    />
    <TextWithImage
      data={{
        copy: "Risk engine",
        title: "Stay in control with best-in-class risk management",
        content:
          "Monitor and manage financial risk in real-time combining our best-in-class risk management technology with our in-house expertise. Set up financial crime alerts that are relevant to your business, and ensure that financial crime prevention is stringent without being heavy-handed.",
        // image: {
        //   src: "/how-we-do-it/img3.svg",
        //   alt: "placeholder alt",
        // },
        animation: "/animations/engines/risk-engine.json",
        links: [{ copy: "Get in touch", to: "https://trustshare.co" }],
      }}
      options={{
        copyColor: "blue",
        titleColor: "black",
        anchor: { id: "riskengine", top: "-80px" },
      }}
    />
  </Layout>
)

export default HowWeDoIt
