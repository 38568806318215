import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import useBreakpoints from "../utils/use-breakpoint"
import { getBg, space } from "../theme"

import Anchor from "../components/anchor"
import Text from "../components/text"
import { Link } from "../components/link"
import Img from "../components/img"
import Physics from "../components/physics"
import Animation from "../components/animation"

export type TextProps = {
  image?: ImageType
  animation?: string
  title: string
  copy?: string
  content: string
  links?: LinkType[]
}
export type Props = {
  data: TextProps
  options?: {
    backgroundColor?: ColorKeys
    titleColor?: ColorKeys
    textColor?: ColorKeys
    copyColor?: ColorKeys
    isTextRight?: boolean
    margin?: string
    padding?: string
    fit?: FitKeys
    anchor?: { id: string; top: string }
  }
}

const Wrapper = styled.div<{ margin?: string | null }>`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  margin: ${props => (props.margin ? props.margin : null)};
`

const LimitWiderPart = styled.div<{ padding?: string; isMobile?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: ${p => (p.padding ? p.padding : "1rem")}
    ${p => (p.isMobile ? 0 : "2rem")};
  ${up("tablet")} {
    padding: ${p => (p.padding ? p.padding : "4rem")} 2rem;
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const BG = styled.div<{ bg?: ColorKeys }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${getBg};
`

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  justify-items: center;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
  }
`
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-gap: 1.5rem;
  justify-items: center;
  text-align: center;
  ${up("desktop")} {
    text-align: left;
    justify-items: flex-start;
  }
`

const LinkContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: repeat(${p => p.size || 1}, auto);
  grid-gap: ${space("small")};
`

const AnimationContainer = styled(Animation)`
  position: relative;
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 620px;
    height: 480px;
  }
`

const TextWithImage: React.FC<Props> = ({ data, options }) => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: {
      mobile: 0,
      tablet: 767,
      desktop: 1279,
    },
  })
  const blocks = [
    data.image ? (
      <Img
        key={`img_block_1`}
        mw="100%"
        mh="auto"
        tw="620px"
        th={"450px"}
        src={data.image.src}
        alt={data.image.alt}
        fit={options && options.fit ? options.fit : "cover"}
      />
    ) : data.animation ? (
      <AnimationContainer key={`img_block_animation_1`} path={data.animation} />
    ) : breakpoint !== "unknown" ? (
      <div
        key={`content_block_physics_1`}
        css={`
          display: grid;
          align-items: center;
          justify-items: center;
          width: 100%;
          height: 510px;
          background-color: #ff88a4;
          ${up("desktop")} {
            width: 620px;
          }
        `}
      >
        <Physics key={`img_block_1`} breakpoint={breakpoint} />
      </div>
    ) : null,
    <Content key={`content_block_1`}>
      <Text
        as="h6"
        type="h5"
        color={options && options.copyColor ? options.copyColor : "black"}
      >
        {data.copy}
      </Text>
      <Text
        type="h4"
        as="h4"
        color={options && options.titleColor ? options.titleColor : "blue"}
      >
        {data.title}
      </Text>
      <Text color={options && options.textColor ? options.textColor : "black"}>
        {data.content}
      </Text>
      {data.links && (
        <LinkContainer size={data.links.length}>
          {data.links.map((link, idx) => (
            <Link key={`link_${idx}_${link.to}`} {...link}>
              {link.copy}
            </Link>
          ))}
        </LinkContainer>
      )}
    </Content>,
  ]

  return (
    <Wrapper margin={options && options.margin ? options.margin : null}>
      <BG bg={options ? options.backgroundColor : undefined} />
      <LimitWiderPart
        padding={options ? options.padding : undefined}
        isMobile={breakpoint === "mobile" && !data.animation && !data.image}
      >
        {options && options.anchor && (
          <Anchor
            id={options && options.anchor.id}
            top={options && options.anchor.top}
          />
        )}
        <Container>
          {options && options.isTextRight && breakpoint === "desktop"
            ? blocks.reverse()
            : breakpoint === "unknown"
            ? null
            : blocks}
        </Container>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default TextWithImage
