import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../components/text"
import { Link } from "../components/link"
import Anchor from "../components/anchor"
import Img from "../components/img"
import Slides, { SlideProps } from "../components/slides"

import { space } from "../theme"

export type Props = {
  data: {
    title: string
    subtitle: string
    content: string
    image?: ImageType
    slides?: SlideProps[]
    link?: LinkType
  }
  options?: {
    anchor?: { id: string; top: string }
  }
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
`

const LimitWiderPart = styled.div<{ isSlides?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${space("normal")};
  padding: 1rem ${p => (p.isSlides ? 0 : "2rem")};
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    padding: ${space("large")} 2rem;
    grid-template-columns: 1216px;
  }
`

const Container = styled.section`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  ${up("tablet")} {
    grid-template-rows: auto auto 1fr;
  }
  grid-gap: ${space("small")};
  align-items: center;
  justify-items: center;
`
const Content = styled.div`
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  grid-gap: 1rem;
  padding: 0 ${space("normal")};
  text-align: center;
  ${up("tablet")} {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  ${up("desktop")} {
    grid-row: 2;
    padding: 0;
    padding-bottom: ${space("large")};
    grid-template-columns: minmax(100px, 300px) 1fr 160px;
    grid-gap: ${space("normal")};
    align-items: flex-start;
    justify-items: space-between;
    text-align: left;
  }
`

const Title = styled(Text)`
  grid-row: 2;
  align-self: center;
  justify-self: center;
  padding: 0 ${space("normal")};
  ${up("tablet")} {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  ${up("desktop")} {
    grid-row: 1;
    padding: 0;
    align-self: flex-start;
    justify-self: flex-start;
  }
`

const DImg = styled(Img)`
  grid-row: 1;
  ${up("desktop")} {
    grid-row: 3;
  }
`

const BigImage: React.FC<Props> = ({ data, options }) => {
  return (
    <Wrapper>
      <LimitWiderPart isSlides={!!data.slides}>
        {options && options.anchor && (
          <Anchor id={options.anchor.id} top={options.anchor.top} />
        )}
        <Container>
          <Title type="h5" color="blue">
            {data.title}
          </Title>
          <Content>
            <Text type="h4">{data.subtitle}</Text>
            <Text>{data.content}</Text>
            {data.link && <Link to={data.link.to}>{data.link.copy}</Link>}
          </Content>
          {data.image && (
            <DImg
              src={data.image.src}
              alt={data.image.alt}
              mw="100%"
              mh="auto"
              tw="620px"
              th={460}
              dw={1216}
              dh={610}
              fit={data.image.fit || "cover"}
            />
          )}
          {data.slides && <Slides slides={data.slides} />}
        </Container>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default BigImage
