import React, { FC } from "react"
import styled from "styled-components"

import { space, getBg } from "../../theme"

export type Props = {
  key: string
  isIf: boolean
  breakpoint: string
  containerSize?: number
  reverse?: boolean
  mobile?: boolean
  index: number
  scale?: number
  slide: {
    key: string
    content: JSX.Element
    bg: ColorKeys
  }
}

const Container = styled.div<{
  index: number
  containerSize?: number
  breakpoint: string
  reverse?: boolean
  mobile?: boolean
  scale?: number
  bg: ColorKeys
}>`
  position: absolute;
  top: ${p =>
    p.mobile
      ? 0
      : p.reverse
      ? -p.index * (p.containerSize || 500) +
        (p.mobile
          ? p.breakpoint === "mobile"
            ? (p.containerSize - 350) / 2
            : 180
          : 160)
      : p.index * (p.containerSize || 500) +
        (p.mobile
          ? p.breakpoint === "mobile"
            ? (p.containerSize - 350) / 2
            : 180
          : 160)}px;
  left: ${p =>
    p.mobile
      ? p.reverse
        ? -p.index * (p.containerSize || 500) +
          (p.mobile
            ? p.breakpoint === "mobile"
              ? (p.containerSize - 350) / 2
              : 180
            : 160)
        : p.index * (p.containerSize || 500) +
          (p.mobile
            ? p.breakpoint === "mobile"
              ? (p.containerSize - 350) / 2
              : 180
            : 160)
      : 0}px;
  transform: scale(${p => p.scale || 1});
  width: 350px;
  height: 200px;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 16px;
  padding: 0 ${space("large")};
  background-color: ${getBg};
  overflow: hidden;
`
const Badge = styled.div<{ bg: ColorKeys }>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.2rem ${space("small")};
  border-radius: 1rem;

  background-color: ${getBg};
`

const Card: FC<Props> = ({
  mobile,
  reverse,
  index,
  containerSize,
  isIf,
  breakpoint,
  scale,
  slide: { key, content, bg },
}) => (
  <Container
    key={key}
    bg={bg}
    breakpoint={breakpoint}
    containerSize={containerSize}
    mobile={mobile}
    reverse={reverse}
    index={index}
    scale={scale}
  >
    <Badge bg="white">{isIf ? "If" : "Then"}</Badge>
    {content}
  </Container>
)

export default Card
