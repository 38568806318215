import React, { useEffect, useState, useRef, useReducer } from "react"
import styled, { css } from "styled-components"
import { useTransition, animated } from "react-spring"
import { ResizeObserver } from "@juggle/resize-observer"
import useMeasure from "react-use-measure"

import Text, { Props as TextProps } from "./text"

export type Props = {
  start: number
  end: number
  step?: number
  duration?: number
  loop?: boolean
  direction?: "normal" | "reverse" | "alternate"
  suffix?: string
  prefix?: string
  textStyle?: TextProps
}

const Container = styled(animated.div)`
  display: inline-block;
  position: relative;
  div {
    position: absolute;
  }
`

const reducer = (state, newState) => ({ ...state, ...newState })

function useInterval(callback, delay = 1000) {
  const savedCallback = useRef()
  const [id, setID] = useState(null)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id = setInterval(tick, delay)
    setID(id)
    return () => clearInterval(id)
  }, [])

  return () => clearInterval(id)
}

const Counter: React.FC<Props> = ({
  start,
  end,
  step,
  duration,
  loop,
  direction,
  suffix,
  prefix,
  textStyle,
}) => {
  const [{ counter, d }, dispatch] = useReducer(reducer, {
    counter: direction && direction === "reverse" ? end : start,
    d: direction && direction === "reverse" ? -1 : 1,
  })
  const [ref, { height, width }] = useMeasure({ polyfill: ResizeObserver })

  const transitions = useTransition(counter, null, {
    from: {
      transform: "translate3d(0,-40px,0)",
      opacity: 0,
    },
    enter: {
      transform: "translate3d(0,0px,0)",
      opacity: 1,
    },
    leave: {
      transform: "translate3d(0,40px,0)",
      opacity: 0,
    },
  })

  const update = () => {
    const c = d === 1 ? counter + (step || 1) : counter - (step || 1)
    if (!!d && c > end && direction === "alternate" && loop)
      dispatch({ d: -1, counter: end })
    else if (d === 1 && c > end && loop) dispatch({ counter: start })
    else if (d === 1 && c > end && !loop) {
      clear()
      dispatch({ counter: end })
    } else if (d === -1 && c < start && direction === "alternate" && loop)
      dispatch({ counter: start, d: 1 })
    else if (d === -1 && c < start && direction === "alternate" && !loop) {
      clear()
      dispatch({ counter: start })
    } else if (d === -1 && c < start && loop) dispatch({ counter: end })
    else if (d === -1 && c < start && !loop) {
      clear()
      dispatch({ counter: start })
    } else dispatch({ counter: c })
  }
  const clear = useInterval(
    update,
    (duration || 1000) / ((end - (start || 0)) / (step || 1))
  )
  return (
    <div
      css={`
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 0.2rem;
      `}
    >
      <Text {...textStyle}>{prefix || null}</Text>
      <Container
        css={`
          height: ${height}px;
          padding-right: ${width.toFixed(0)}px;
        `}
      >
        {transitions.map(
          ({ item, props, key }) =>
            item && (
              <animated.div key={key} style={props} ref={ref}>
                <Text {...textStyle}>{item}</Text>
              </animated.div>
            )
        )}
      </Container>
      <Text {...textStyle}>{suffix || null}</Text>
    </div>
  )
}

export default Counter
