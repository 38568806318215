import React, { FC } from "react"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import lottie, { AnimationItem } from "lottie-web"
import { ResizeObserver } from "@juggle/resize-observer"
import useMeasure from "react-use-measure"

import { color } from "../../theme"

import Card, { Props as CardProps } from "./card"

export type SlideProps = {
  title: string
  card1: CardProps
  card2: CardProps
}
export type Props = {
  slides: SlideProps[]
  breakpoint: "desktop" | "mobile" | "tablet" | "unknown"
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 650px;
  margin: 0 auto;
  background-color: ${color("rose")};
  overflow: hidden;
`
const Wrapper = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  margin: 0 auto;
  grid-template-rows: min-content min-content min-content;
  grid-gap: 0;
  align-items: center;
  justify-items: center;
`
const AnimationContainer = styled.div`
  position: relative;
  top: 25px;
  width: 100%;
  height: 200px;
  transform: rotate(-90deg);
  ${up("tablet")} {
    width: 360px;
    height: 200px;
  }
`

const Slides: FC<Props> = ({ slides, breakpoint }) => {
  const [index, setIndex] = React.useState(0)
  const [animation, setAnimation] = React.useState<AnimationItem>()
  const animationRef = React.createRef()
  const [loaded, setL] = React.useState(false)
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver })

  React.useEffect(() => {
    if (animationRef.current && !loaded) {
      const a = lottie.loadAnimation({
        container: animationRef.current as any,
        renderer: "svg",
        loop: false,
        autoplay: false,
        name: "cog",
        path: "/animations/engines/cog.json",
      })
      a.setSpeed(2)
      setAnimation(a)
      setL(true)
    }
  }, [animationRef])
  const nextIdx = () => {
    if (animation) {
      animation.stop()
      if (index + 1 < slides.length) {
        animation.setDirection(1)
        setIndex(index + 1)
      } else {
        animation.goToAndStop(132, true)
        animation.setDirection(-1)
        setIndex(0)
      }
      animation.play()
    }
  }

  React.useEffect(() => {
    const timer = setInterval(nextIdx, 5500)
    return () => clearInterval(timer)
  }, [animation, index])

  return (
    <Container ref={ref}>
      <Wrapper>
        <div
          css={`
            position: relative;
            top: 25px;
            width: 100%;
            height: 200px;
            overflow: hidden;
          `}
        >
          <div
            css={`
              position: absolute;
              top: 0;
              height: 200px;
              width: ${slides.length * width}px;
              transition: transform 0.6s 0.6s cubic-bezier(0.37, 0.15, 0.47, 1);
              transform: translateX(-${width * index}px);
            `}
          >
            {slides.map((s, idx) => (
              <Card
                mobile
                breakpoint={breakpoint}
                containerSize={width}
                isIf
                bgBadge="green"
                slide={s.card1}
                index={idx}
                scale={0.8}
              />
            ))}
          </div>
        </div>
        <AnimationContainer ref={animationRef} />
        <div
          css={`
            position: relative;
            top: 25px;
            width: 100%;
            height: 200px;
            overflow: hidden;
          `}
        >
          <div
            css={`
              position: absolute;
              top: 0;
              height: 200px;
              width: ${slides.length * width}px;
              transition: transform 0.6s 0.6s cubic-bezier(0.37, 0.15, 0.47, 1);
              transform: translateX(${width * index}px);
            `}
          >
            {slides.map((s, idx) => (
              <Card
                mobile
                breakpoint={breakpoint}
                containerSize={width}
                isIf={false}
                reverse
                bgBadge="green"
                slide={s.card2}
                index={idx}
                scale={0.8}
              />
            ))}
          </div>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Slides
